export const ActionTypes = {
    MENU_GENRE: 'MENU_GENRE',
    MENU_SEARCH: 'MENU_SEARCH',
    MOVIES_NOWPLAYING: 'MOVIES_NOWPLAYING',
    MOVIES_MOSTPOPULAR: 'MOVIES_MOSTPOPULAR',
    MOVIES_UPCOMING: 'MOVIES_UPCOMING',
    TVS_MOSTPOPULAR: 'TVS_MOSTPOPULAR',
    MOVIES_TRENDING: 'MOVIES_TRENDING',
    MOVIES_BY_GENRE: 'MOVIES_BY_GENRE',
    CLEAR_MOVIES_BY_GENRE: 'CLEAR_MOVIES_BY_GENRE',

    MOVIE_DETAILS: 'MOVIE_DETAILS',
    MOVIES_SIMILAR: 'MOVIES_SIMILAR',
    TVS_SIMILAR: "TVS_SIMILAR",
    FETCH_MOVIE_TRAILER: 'FETCH_MOVIE_TRAILER',

    MOVIE_ACTORS: 'MOVIES_ACTORS',
    ACTOR_DETAILS: 'ACTOR_DETAILS',
    ACTOR_ALL_MOVIES: 'ACTOR_ALL_MOVIES',

    PAGINATION: 'PAGINATION'

}